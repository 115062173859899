import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MPlay } from '@models/api-models/plays-api.models';

import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { modalShareComponent } from '@shared/components/modals/modalShare/modalShare.component';
import { modalInfoComponent } from '@shared/components/modals/modalInfo/modalInfo.component';
import { MatDialog } from '@angular/material/dialog';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { bottomSheetRegalarComponent } from '@shared/components/bottomSheet/bottomSheetRegalar/bottomSheetRegalar.component';
import { bottomSheetAbonoComponent } from '@shared/components/bottomSheet/bottomSheetAbono/bottomSheetAbono.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { EDevice } from '@enums/common/sizes.enum';
import { now } from 'moment';
import { ToastrService } from "ngx-toastr";
import { logEvent } from '@firebase/analytics';
import { FireBase } from '@classes/fireBase';
import { FiltrosService } from '@api/services/filtros.service';
import * as moment from 'moment';
import { BottomSheetRepetirJugadaComponent } from '@shared/components/bottomSheet/bottomSheetRepetirJugada/bottomSheetRepetirJugada.component';
import { modalAlertaComponent } from '@shared/components/modals/modalAlerta/modalAlerta.component';
import { BetService } from '@modules/games/bet.service';
import { UserApiService } from '@api/services/user.service';
import { ConfigurationApiService } from '@api/services/configuration.service';
import { ParticipationService } from '@api/services/participation.service';
import { GroupService } from '@api/services/group.service';


@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: [
		'./subscription.component.scss',
	]
})
export class SubscriptionComponent extends FireBase implements OnInit {

	selected = false;

	@Input() play: any;
	@Input() last;
	@Input() jugada: any;
	@Input() device: any;
	@Input() abono: boolean = false
	@Output() dropAbono: EventEmitter<MPlay> = new EventEmitter()

	public precioGuindaFinal: number;
	public saldo: number;
	public guindaConfig: boolean = false;

	private route: string;

	public grupo = null;
	public aleatorio = false;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private playsApiService: PlaysAPIService,
		private bottomSheetRegalar: MatBottomSheet,
		private bottomSheetAbono: MatBottomSheet,
		private toastrService: ToastrService,
		private filterService: FiltrosService,
		private sheet: MatBottomSheet,
		private betService: BetService,
		private userService: UserApiService,
		private configService: ConfigurationApiService,
		private routerActive: ActivatedRoute,
		private participationService: ParticipationService,
		private grupoService: GroupService
	) {
		super();

		this.userService.usuarioObservable.subscribe( x => {
			if(x)
				this.saldo = x.client.getSaldoTotal();
		})
		this.configService.observableGuinda.subscribe(x => {
			this.guindaConfig = x;
		});
	}

	
	ngOnInit(): void {
		const url = this.router.url.split('/')
		if (url.includes('mis-grupos')) {
			this.route = '/cliente/mis-grupos/detalles/' + url[4] + '/' + url[5] + '/subscripcion';
			this.grupo = url[4];
		}
		else {
			this.route = '/cliente/subscripciones';
		}

		if (this.play.jugada) {
			if (this.play.jugada.abono) {
				this.abono = true;
				if (this.play.jugada.abono.aleatorio == 1) {
					this.aleatorio = true;
				}
			}
		}
		else {
			this.abono = true;
			this.aleatorio = true;
		}
	}
	
	openOptions() {
		this.selected = !this.selected;
	}
	
	public detailPlay(play): void {
		if (this.play.participacion)
			this.infoParticipacion()
		else {
			this.router.navigate([this.route, play.jugada.id]);
		}
	}

	public desabono() {
		if (this.play.jugada) {
			if (this.grupo == null) {
				this.playsApiService.desabonoPlay(this.play.jugada.id).subscribe(res => {
					this.play.abono = null;
					this.abono = false;	
					this.toastrService.success("Abono cancelado", "", {
						positionClass: 'toast-bottom-center',
					});
				});
			}
			else {
				this.grupoService.desabonoPlay(this.play.jugada.id, this.grupo).subscribe(res => {
					this.play.abono = null;
					this.abono = false;	
					this.toastrService.success("Abono cancelado", "", {
						positionClass: 'toast-bottom-center',
					});
				});
			}
		}
		else {
			this.participationService.unsubscribeParticipacion(this.play.participacion.id, this.grupo).subscribe(res => {
				if (res) {
					this.play.abono = null;
					this.abono = false;
					this.toastrService.success("Abono cancelado", "", {
						positionClass: 'toast-bottom-center',
					});
				}
			})
		}
	}

	public repeat() {
		this.sheet.open(BottomSheetRepetirJugadaComponent, { data: { play: this.play, modoGrupo: false } })
	}

	openRegalarJugada() {

		this.bottomSheetRegalar.open(bottomSheetRegalarComponent, {
			hasBackdrop: true,
			backdropClass: 'backdropBottomSheet',
			panelClass: 'bottomSheetContainerJugadaRegalada',
			data: { jugada_id: this.play.id }
		});
	}

	openAbono() {
		if (this.play.participacion) {
			const texto = 'Jugarás una participación con el mismo importe cada vez que juegue la peña. Mucha suerte';
			const alerta = this.dialog.open(modalYesNoComponent, {
				panelClass: 'modal-custom',
				data: {
					title: '¡Atencion vas a abonarte!',
					texto,
					buttonConfirm: 'Abonarme',
					btnConfirmColor: 'blue',
					buttonCancel: 'Cancelar',
					showIcon: false,
					showOptionCheck: true
				}
			});
	
			alerta.afterClosed().subscribe(res => {
				if (res) {
					this.participationService.subscribeParticipacion(this.play.id, this.grupo).subscribe(res => {
						this.play.participacion.abono = true;
						this.abono = true;
						this.aleatorio = true;
					});
					this.toastrService.success("Participación abonada", "", {
						positionClass: 'toast-bottom-center',
					});
				}
			});
		}
		else {
			if (this.play.jugada.idGame == 1 && Number(this.play.jugada.price) < 1) {
				this.infoAbonoBonolotoMal();
				return;
			}
	
			let alerta;
			let day = new Date(this.play.jugada.dateSorteo);
			if (this.play.jugada.idGame == 7 && day.getDay() != 4) {
				const texto = 'La suscripción consiste en que cada lunes se comprara tu jugada de forma automática para toda la semana con los mismos números. Además, también te guardaremos el número para Navidad y El Niño. Mucha suerte';
				alerta = this.dialog.open(modalYesNoComponent, {
					panelClass: 'modal-custom',
					data: {
						title: '¡Atencion vas a suscribirte!',
						texto,
						buttonConfirm: 'Abonarme',
						btnConfirmColor: 'blue',
						buttonCancel: 'Cancelar',
						showIcon: false,
						showOptionCheck: true
					}
				});
			}
			else {
				const texto = 'La suscripción consiste en que cada lunes se comprara tu jugada de forma automática para toda la semana con los mismos números. Mucha suerte';
				alerta = this.dialog.open(modalYesNoComponent, {
					panelClass: 'modal-custom',
					data: {
						title: '¡Atencion vas a suscribirte!',
						texto,
						buttonConfirm: 'Abonarme',
						btnConfirmColor: 'blue',
						buttonCancel: 'Cancelar',
						showIcon: false,
						showOptionCheck: true
					}
				});
			}
	
			alerta.afterClosed().subscribe(res => {
				if (res) {
					this.playsApiService.abonoPlay(this.play.jugada.id).subscribe(res => {
						this.play.abono = res;
						this.abono = this.play.abono != null;
					});
				}
			});
		}
	}

	public infoParticipacion(): void {
		this.dialog.closeAll();
		let dialogAlert = this.dialog.open(modalAlertaComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Suscripción peña ' + this.play.participacion.producto.penya.nombre,
				texto: 'Estas suscrito a la peña ' + this.play.participacion.producto.penya.nombre + ' y las participaciones puedes verlas en la pestaña participaciones.',
				buttonConfirm: 'Continuar',
				buttonCancel: 'Continuar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});
	}
	public infoAbonoBonolotoMal(): void {
		this.dialog.closeAll();
		let dialogAlert = this.dialog.open(modalAlertaComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Abono Bonoloto',
				texto: 'Solo es posible abonar jugadas de Bonoloto con un precio mínimo de 1€',
				buttonConfirm: 'Continuar',
				buttonCancel: 'Continuar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});
	}

	capitalize(value: string): string {
		if (!value) return value;
		return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
	}
}
