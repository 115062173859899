<div class="play-wrapper">
	<div class="fondo imagen" *ngIf="imagen" (click)="detailPlay(play)">
		<img class="filtro" src="/assets/images/icons/penya.svg" alt="Imagen Peña">
	</div>
	<div class="imagen" *ngIf="!imagen" (click)="detailPlay(play)">
		<img src="{{play.producto.penya.imagen}}" alt="Imagen Peña">
	</div>
	<div class="play-details" (click)="detailPlay(play)">
		<div class="play-info">
			<span class="play-game-name">Participación</span>
			<span class="play-game-price">{{play.importe}}€</span>
		</div>
		<div class="play-bet" (click)="detailPlay(play)">
			<span style="font-weight: 600;">{{play.producto.penya.nombre}}</span>
		</div>
	</div>
	<img class="image-status" src="/assets/images/icons/plays-list/prize_icon.svg" *ngIf="play.state == 3">
	<img (click)="openOptions()" class="play-game-details" src="/assets/images/icons/play-detail/play-details.svg">
</div>
<div class="actions-play" *ngIf="selected">
    <div class="group-action" *ngIf="!play.abono" (click)="openAbono()" >
        <img src="/assets/images/icons/play-detail/abono-play.svg">
        <span class="action-name">Suscribirse</span>
    </div>
    <div class="group-action" *ngIf="play.abono" (click)="desabono()">
        <img src="/assets/images/icons/play-detail/abono-play-full.svg">
        <span class="action-name">Desuscribirse</span>
    </div>
	<div class="group-action" (click)='repeat()'>
		<img src="/assets/images/icons/play-detail/repeat-play.svg">
		<span class="action-name">Repetir</span>
	</div>
</div>