import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MClient } from '@models/client.model';
import { MPerson } from '@models/person.model';
import { ApiRouterService } from '@shared/services/api-router.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ReponseStandar } from "@models/api-models/response-standar.model";

/**
 * User API service. Provides the app with user data from the API.
 */
@Injectable({
	providedIn: 'root'
})
export class UserToolsService extends ApiRouterService {

	constructor(
		private http: HttpClient,
	) {
		super();
	}

	getSorteosBusqueda(): Observable<any> {
		return this.http.get(`${this.URL}getsorteosbusqueda`);
	}

	buscarNumero(draw_id: number, date: string, number: number, numero: string): Observable<any> {
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };

		let params = {
			'draw_id': draw_id,
			'date': date,
			'number': number,
			'numero': numero
		}

		return this.http.post(`${this.URL}buscarnumero`, params, httpOptions);
	}

	searchNumber(date: string, game_id: number, numero: string): Observable<any> {
		this.httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const httpOptions = { headers: this.httpHeaders };

		let params = {
			'date': date,
			'game_id': game_id,
			'numero': numero
		}

		return this.http.post(`${this.URL}searchnumber`, params, httpOptions);
	}
}
