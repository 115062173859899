<div class="spinner-wrapper" *ngIf="loading">
	<app-spinner forceTo='component-sized'></app-spinner>
</div>

<cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll" [ngClass]="{'grupo': modeGroup}">
	<ng-container *ngIf="finalPlays?.length > 0">
		<div class="plays-row" *ngFor="let play of finalPlays; let i = index; let last = last">
			<ng-container *ngIf="modeGroup; else noGroup">
				<app-participation-product [play]="play" [device]="this.device" [abono]="abonos" (dropAbono)="dropAbonoList($event)"
					[grupo]="grupo" [ngClass]="{'mb5': last}"></app-participation-product>
			</ng-container>
			<ng-template #noGroup>
				<app-participation-product [play]="play" [device]="this.device" [abono]="abonos" (dropAbono)="dropAbonoList($event)"
					[ngClass]="{'mb5': last}"></app-participation-product>
			</ng-template>
		</div>
	</ng-container>
	<div class="no-plays" *ngIf="finalPlays?.length == 0 && loading == false">
		<h3 class="no-plays-text">
			Aquí parece que todavía no hay mucho que ver
		</h3>
		<img class="image" src="/assets/images/icons/plays-list/no-plays.svg" />
	</div>
	<div style="display: flex; justify-content: center; margin: 25px;" *ngIf="spinnerBottomLoadVisibility">
		<mat-progress-spinner class="spinner" [mode]="'indeterminate'"
			[diameter]="35"> </mat-progress-spinner>
	</div>
</cdk-virtual-scroll-viewport>