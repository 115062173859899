<div class="register-wrapper">
	<form id="register-form" autocomplete="off" novalidate [formGroup]="registerForm" (ngSubmit)="register()"
		class="register-form">

		<mat-form-field appearance="outline" class="form-input">
			<mat-label>Nombre</mat-label>
			<input matInput placeholder="Nombre" formControlName="name" required>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-input">
			<mat-label>Email</mat-label>
			<input matInput type="email" placeholder="Email" formControlName="email" required>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-input">
			<mat-label>Contraseña</mat-label>
			<input matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" formControlName="password"
				required (input)="nofirst()">
			<button matSuffix type="button" (click)="hidePass()" class="button-pass">
				<img *ngIf="!hide" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
				<img *ngIf="hide" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
			</button>
			<mat-error *ngIf="passwordInvalid" style="padding-bottom: 1rem;">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula, minúscula, número y caracter especial.</mat-error>
			<mat-hint *ngIf="first" style="padding-bottom: 1rem;">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula, minúscula, número y caracter especial.</mat-hint>
		</mat-form-field>

		<div *ngIf="first || passwordInvalid" style="padding-bottom: 1rem;"></div>

		<mat-form-field appearance="outline" class="form-input">
			<mat-label>Fecha de Nacimento</mat-label>
			<input matInput [matDatepicker]="datePick" placeholder="Fecha de nacimiento" formControlName="birthDate" id="birthDate"
				readonly (click)="datePick.open()" [max]="maxDate" required>
			<mat-datepicker-toggle matSuffix [for]="datePick"></mat-datepicker-toggle>
			<mat-datepicker #datePick [startAt]="age18"></mat-datepicker>
			<mat-error *ngIf="birthDateInvalid">Debe ser mayor de edad.</mat-error>
		</mat-form-field>

		<span class="error-message"
			*ngIf="registerMessage && (registerMessage.type == 'warning' || registerMessage.type == 'error')">{{
			registerMessage.message }}</span>

		<button mat-raised-button color="miloto" type="submit" [disabled]="!registerForm.valid || registerLoading"
			class="register-button">
			<span *ngIf="!registerLoading">Crear cuenta</span>
			<mat-icon *ngIf="registerLoading">
				<mat-spinner diameter="20"></mat-spinner>
			</mat-icon>
		</button>

	</form>
	<div class="social-wrapper">

		<!--
		<button mat-raised-button class="social-button" (click)="loginGoogle()">
			<img src="/assets/images/pages/options-auth/logo-google.svg" />
			Acceder con Google
		</button>
		-->

		<asl-google-signin-button class="social-button" type='standard' size='large' shape='pill' theme='outline' text="continue_with" logo_alignment="center" locale="es" width="320" (click)="this.closeModal()"></asl-google-signin-button>

		<button mat-raised-button class="social-button" (click)="loginApple($event)">
			<img src="/assets/images/icons/appel_icon.png" />
			Continuar con Apple
		</button>

		<!--
		<button mat-raised-button class="social-button" (click)="loginFacebook()">
			<img src="/assets/images/pages/options-auth/logo-facebook.svg" />
			Acceder con Facebook
		</button>
		-->
	</div>

	<ng-container *ngIf="recaptchaMode === 'invisible'">
		<re-captcha
		  size="invisible"
		  #invisible
		  (errored)="errorCaptcha()"
		  errorMode="handled"
		  (resolved)="resolvedCaptcha('Invisible reCAPTCHA response', $event)"
		>
		</re-captcha>
	  </ng-container>

	<div class="notice">
		<a class="text-info">¿Ya estás registrado?
			<strong (click)="changeAction()" class="miloto-primary">
				<u>Inicia sesión</u>
			</strong>
		</a>
	</div>

</div>