<div class="data-body" [ngClass]="{'grupo': modeGroup}">

	<span *ngIf="modeGroup">
		<div class="balance-wrapper">
			<span class="balance-text">
				<ng-container *ngIf="miembro; else personal">
					Saldo de {{miembro.nombre}} {{miembro.apellido_1}}
				</ng-container>
				<ng-template #personal>
					Saldo Total
				</ng-template>
			</span>
			<a class="balance-number">{{ saldo | currency:'EUR'}}</a>
			<span class="balance-text" *ngIf="cliente == null">Tu saldo - {{ saldoMiembro | currency:'EUR'}}</span>
			<div class="actions-wrapper">
				<button mat-raised-button class="retrieve-funds" (click)="retirarBtn()" [disabled]="cliente != null && cliente.id != miembro_id">Retirar</button>
				<button mat-raised-button class="add-funds" (click)="ingresarBtn()" [disabled]="cliente != null && cliente.id != miembro_id">Cargar</button>
			</div>
		</div>
	</span>

	<span *ngIf="!modeGroup">
		<div class="balance-wrapper">
			<span class="balance-text">Saldo Total</span>
			<a class="balance-number">{{ saldo | currency:'EUR'}}</a>
			<div class="actions-wrapper">
				<button mat-raised-button class="retrieve-funds" (click)="retirarBtn()">Retirar</button>
				<button mat-raised-button class="add-funds" (click)="ingresarBtn()">Cargar</button>
			</div>
		</div>
	</span>

	<div class="transactions-wrapper">
		<span class="transactions-text">Actividad</span>
		<div class="horizontal" (mousedown)="onMouseDown($event)" (mouseleave)="onMouseLeave()" (mouseup)="onMouseUp()">
			<div class="scroll-wrapper">
				<div class="flex" #scrollContent>
					<div class="option" (click)="onCategoryClick(null)" [ngClass]="{'selected': null === selectedCategory}">Ver Todo</div>
					<div class="option" (click)="onCategoryClick('INGRESO')" [ngClass]="{'selected': 'INGRESO' === selectedCategory}">Ingresos</div>
					<div class="option" (click)="onCategoryClick('PAGO')" [ngClass]="{'selected': 'PAGO' === selectedCategory}">Pagos</div>
					<div class="option" (click)="onCategoryClick('COBRO')" [ngClass]="{'selected': 'COBRO' === selectedCategory}">Transferencias</div>
					<div class="option" (click)="onCategoryClick('INFORMACION')" [ngClass]="{'selected': 'INFORMACION' === selectedCategory}">Información</div>
					<div class="option" (click)="onCategoryClick('PROMOCION')" [ngClass]="{'selected': 'PROMOCION' === selectedCategory}">Promociones</div>
				</div>
				<div class="scroll-gradient"></div>
			</div>
		</div>
		<ng-scrollbar class="scroll" itemSize="20">
			<div *ngFor="let movimiento of actividad; let i = index" (click)="openSheet(movimiento)" class="transaction">
				<div class="transaction-header">
					<span class="transaction-type">{{ title[i] }}</span>
					<span class="account-total-number">{{ movimiento.saldo_total | currency:'EUR'}}</span>
				</div>
				<div class="transaction-body">
					<div class="transaction-date">{{movimiento.fecha | date:'dd/MM/Y | H:mm'}}</div>
					<div class="transaction-number" [ngClass]="{ingreso: !comprobarCobro(movimiento.importe), cobro: comprobarCobro(movimiento.importe)}">
						{{ movimiento.importe | currency:'EUR'}}
					</div>
				</div>
			</div>
		</ng-scrollbar>
	</div>
</div>