
<div class="games-primary-header-wrapper card" [ngClass]="{'wrapper-manual': manual }">

  <div class="header-row mb1">

    <span class="row-text">Elige cuándo quieres jugar</span>
 
    <div class="days-selection">

      <cdk-virtual-scroll-viewport orientation="horizontal" class="scroll">

        <div class="days" [ngClass]="{'selected': selected}">
          <app-day class="day" *ngFor="let game of games" [date]="game" (selectedEvent)="dateSelected($event)" [freePlay]="freePlay" (scrollEvent)="scroll($event)" (mousedown)="startLogMouseDown(game)" (mouseup)="clearLogMouseDown()"></app-day>
        </div>

      </cdk-virtual-scroll-viewport>
      
    </div>

  </div>

</div>